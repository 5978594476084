import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="row text-center">
        <h1>Ojoj taka strona nie istnieje!</h1>
        <div className="btn" style={{ backgroundColor: "#0010B3" }}>
          <Link to="/">Wróć do stron głównej</Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
