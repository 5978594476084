const spacebetween = ({color, height}) => {
  return (
    <div
      className="row m-0"
      style={{ ...color === 'blue' ? {backgroundColor:'#0010B3'}: color === 'yellow' ? {backgroundColor:'rgb(255, 200, 0)'}: color === 'grey' ?{backgroundColor:"#292930"}: null  , height:"70px" }}
      // style={{ backgroundColor: "rgb(27, 20, 100", height: "50px" }}
    ></div>
  );
};

export default spacebetween;
