const Loading = () => {
  return (
    <div className="container d-flex align-items-center justify-content-center d-flex" style={{width:"100vw",height:"50vh"}}>
        <div className="row ">
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden text-warning">Loading...</span>
          </div>
      </div>
    </div>
  );
};

export default Loading;
