import React, { Suspense } from 'react';
import {useState} from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import Loading from "./components/Loading"
import Spacebetween from "./components/Spacebetween";
import Error404 from "./components/Error404";
import ScrollUp from "./components/ScrollUp";

// import Footer from "./components/Footer";
// import Video from "./components/Video";
// import Text from "./components/Text";
// import Gallery from "./components/Gallery";
// import TextAndPhoto from "./components/TextAndPhoto";
// import TextAndPhoto2 from "./components/TextAndPhoto2";
// import Cooperation from "./components/Cooperation";
// import FormsEmail from "./components/FormsEmail";
// import International from "./components/International";
// import NavTop from "./components/NavTop";
// import NavBar from "./components/NavBar";

const Footer  =  React.lazy(() => import("./components/Footer"));
const Video  =  React.lazy(() => import("./components/Video"));
const Text  =  React.lazy(() => import("./components/Text"));
const Gallery  =  React.lazy(() => import("./components/Gallery"));
const TextAndPhoto  =  React.lazy(() => import("./components/TextAndPhoto"));
const TextAndPhoto2  =  React.lazy(() => import("./components/TextAndPhoto2"));
const Cooperation  =  React.lazy(() => import("./components/Cooperation"));
const FormsEmail  =  React.lazy(() => import("./components/FormsEmail"));
const International  =  React.lazy(() => import("./components/International"));
const NavTop  =  React.lazy(() => import("./components/NavTop"));
const NavBar  =  React.lazy(() => import("./components/NavBar"));
const InternationalSite  =  React.lazy(() => import("./components/InternationalSite"));
const ChlodnieSite = React.lazy(() =>import("./components/ChlodnieSite"));
const FirankaSite = React.lazy(() =>import("./components/FirankaSite"));
const IzotermySite = React.lazy(() =>import("./components/IzotermySite"));
const PlandekaSite = React.lazy(() =>import("./components/PlandekaSite"));
const EkspresowySite = React.lazy(() =>import("./components/EkspresowySite"));
const DrobnicowySite = React.lazy(() =>import("./components/DrobnicowySite"))
const AdrSite = React.lazy(() => (import("./components/AdrSite")));
function App() {
  const [germanyLang, setGermanyLang] = useState(false);
  return (
    <>
      <NavTop />
      <Routes>

    <Route path='*' element={<><NavBar lang="pl"/><Spacebetween/><Error404/></>}/>
          <Route
          path="/"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - Transport Krajowy i Międzynarodowy</title>
                  <meta
                    name="description"
                    content="PHU Marpol Mariusz Michalicki - Profesjonalne Transporty Krajowe i Międzynarodowe na trasie Niemcy, Holandia, Hiszpania, Norwegia, Szwecja, Wielka Brytania"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="transport towarowy, transport żywności, transport międzynarodowy"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu" />
                </Helmet>
                <header>
                <NavBar lang="pl"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="pl" />
                  </div>
                  <Text lang="pl" color="#FFC800"/>
                  <Gallery lang="pl" color="#0010B3"/>
                  {/* <WorkingOn lang="pl" color="#0010B3"/> */}
                  <TextAndPhoto lang="pl" color="#FFC800"/>
                  <TextAndPhoto2 lang="pl" color="#0010B3"/>
                  {/* <Country /> */}
                  <International lang="pl" color="#FFC800"/>
                  <Cooperation lang="pl" color="#333433"/>
                  {/* <TransportQuote /> */}
                  <FormsEmail lang="pl" color="#0010B3"/>
                  <Footer lang="pl" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
          <Route
          
          path="/nb"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - Nationella och internationella transporter</title>
                  <meta
                    name="description"
                    content="PHU Marpol Mariusz Michalicki - Profesjonalne Transporty Krajowe i Międzynarodowe na trasie Niemcy, Holandia, Hiszpania, Norwegia, Szwecja, Wielka Brytania"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="transport towarowy, transport żywności, transport międzynarodowy"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb" />
                </Helmet>
                <header>
                <NavBar lang="nb"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="nb" />
                  </div>
                  <Text lang="nb" color="#FFC800"/>
                  <Gallery lang="nb" color="#0010B3"/>
                  {/* <WorkingOn lang="pl" color="#0010B3"/> */}
                  <TextAndPhoto lang="nb" color="#FFC800"/>
                  <TextAndPhoto2 lang="nb" color="#0010B3"/>
                  {/* <Country /> */}
                  <International lang="nb" color="#FFC800"/>
                  <Cooperation lang="nb" color="#333433"/>
                  {/* <TransportQuote /> */}
                  <FormsEmail lang="nb" color="#0010B3"/>
                  <Footer lang="pl" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
          <Route
          path="/sv"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - Nationella och internationella transporter</title>
                  <meta
                    name="description"
                    content="PHU Marpol - Professionella inrikes och internationella transporter på sträckan Tyskland, Holland, Spanien, Norge, Sverige, Storbritannien och Norge"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="godstransporter, livsmedelstransporter, internationella transporter"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv" />
                </Helmet>
                <header>
                <NavBar lang="sv"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="sv" />
                  </div>
                  <Text lang="sv" color="#FFC800"/>
                  <Gallery lang="sv" color="#0010B3"/>
                  {/* <WorkingOn lang="pl" color="#0010B3"/> */}
                  <TextAndPhoto lang="sv" color="#FFC800"/>
                  <TextAndPhoto2 lang="sv" color="#0010B3"/>
                  {/* <Country /> */}
                  <International lang="sv" color="#FFC800"/>
                  <Cooperation lang="sv" color="#333433"/>
                  {/* <TransportQuote /> */}
                  <FormsEmail lang="sv" color="#0010B3"/>
                  <Footer lang="sv" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
          <Route
          path="/en"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - National and International Transport</title>
                  <meta
                    name="description"
                    content="PHU Marpol Mariusz Michalicki - Profesjonalne Transporty Krajowe i Międzynarodowe na trasie Niemcy, Holandia, Hiszpania, Norwegia, Szwecja, Wielka Brytania"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="transport towarowy, transport żywności, transport międzynarodowy"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en" />
                </Helmet>
                <header>
                <NavBar lang="en"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="en" />
                  </div>
                  <Text lang="en" color="#FFC800"/>
                  <Gallery lang="en" color="#0010B3"/>
                  {/* <WorkingOn lang="pl" color="#0010B3"/> */}
                  <TextAndPhoto lang="en" color="#FFC800"/>
                  <TextAndPhoto2 lang="en" color="#0010B3"/>
                  {/* <Country /> */}
                  <International lang="en" color="#FFC800"/>
                  <Cooperation lang="en" color="#333433"/>
                  {/* <TransportQuote /> */}
                  <FormsEmail lang="en" color="#0010B3"/>
                  <Footer lang="en" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
         <Route
          path="/de"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - Nationaler und internationaler Verkehr </title>
                  <meta
                    name="description"
                    content="PHU Marpol  Mariusz Michalicki- Profesjonalne Transporty Krajowe i Międzynarodowe na
                    trasie Niemcy, Holandia, Hiszpania, Norwegia, Szwecja, Wielka Brytania"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="transport towarowy, transport żywności, transport międzynarodowy"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de" />
                </Helmet>
                <header>
                <NavBar lang="de"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="de" />
                  </div>
                  <Text lang="de" color="#FFC800"/>
                  <Gallery lang="de" color="#0010B3"/>
                  {/* <WorkingOn lang="pl" color="#0010B3"/> */}
                  <TextAndPhoto lang="de" color="#FFC800"/>
                  <TextAndPhoto2 lang="de" color="#0010B3"/>
                  {/* <Country /> */}
                  <International lang="de" color="#FFC800"/>
                  <Cooperation lang="de" color="#333433"/>
                  {/* <TransportQuote /> */}
                  <FormsEmail lang="de" color="#0010B3"/>
                  <Footer lang="de" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
        <Route
          path="/fr"
          element={
            <Suspense fallback={<Loading/>}>
              <HelmetProvider>
                <Helmet>
                  <title>PHU MARPOL - Transport national et international</title>
                  <meta
                    name="description"
                    content="PHU Marpol Mariusz Michalicki - Profesjonalne Transporty Krajowe i Międzynarodowe na trasie Niemcy, Holandia, Hiszpania, Norwegia, Szwecja, Wielka Brytania"
                  />
                  <meta name="theme-color" content="#000000" />
                  <meta
                    name="keywords"
                    content="transport towarowy, transport żywności, transport międzynarodowy"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr" />
                </Helmet>
                <header>
                <NavBar lang="fr"/>
                  <ScrollUp />
                  </header>
                  <main>
                    <div className="container-fluid">
                  <Video lang="fr" />
                  </div>
                  <Text lang="fr" color="#FFC800"/>
                  <Gallery lang="fr" color="#0010B3"/>
                  <TextAndPhoto lang="fr" color="#FFC800"/>
                  <TextAndPhoto2 lang="fr" color="#0010B3"/>
                  <International lang="fr" color="#FFC800"/>
                  <Cooperation lang="fr" color="#333433"/>
                  <FormsEmail lang="fr" color="#0010B3"/>
                  <Footer lang="fr" />
                  </main>
              </HelmetProvider>
              </Suspense>
          }
        ></Route>
             {/* LANG PL PODSTRONY */}
        <Route
          path="/transport-temperaturze-kontrolowanej"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                    Transport w Temperaturze Kontrolowanej | Transportu Ekspresowego
                  </title>
                  <meta
                    name="description"
                    content="Oferujemy usługę transportu ekspresowego oraz transportu towarów wymagających szczególnych warunków temperaturowych. Gwarantujemy bezpieczny i szybki transport"
                  />
                  <meta name="keywords" content="temperatura kontrolowana,  transportu ekspresowego, transportu towarów wymagających szczególnych warunków temperaturowych" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/transport-temperaturze-kontrolowanej" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <EkspresowySite
                  title="Transport w Temperaturze Kontrolowanej, oraz Dedykowany Transport Ekspresowy"
                  titlePosition="right"
                  color="blue"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/transport-miedzynarodowy"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Transport Międzynarodowy - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Realizujemy zlecenia transportowe, gwarantując przygotowaniu dowolnego typu towarów do przewozu na trasach krajowych i międzynarodowych."
                  />
                  <meta name="keywords" content="transport międzynarodowy, zlecenia transportowe, trasy krajowe" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/transport-miedzynarodowy" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="Transport Międzynarodowy"
                  titlePosition="left"
                  color="yellow"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
          <Route
          path="/transport-chlodniczy"
          element={
            <HelmetProvider>
              <Helmet>
                <title>Transport Chłodniczy - PHU MARPOL</title>
                <meta
                  name="description"
                  content="Zajmujemy się transportem chłodniczy takich produktów jak: mięso, ryby, owoce morza, nabiał, owoce i warzywa, rośliny, artykuły rolnicze, medyczne, kosmetyki."
                />
                <meta name="keywords" content="transport chłodniczy, transport miesa, transport ryb, transpor nabial," />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/transport-chlodniczy" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Transport Chłodniczy"
                titlePosition="right"
                color="blue"
                lang="pl"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
        <Route
          path="/transport-materialow-niebezpiecznych"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                    Transport Materialow Niebezpiecznych ADR - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Posiadamy doświadczeniem w transporcie materiałów niebezpiecznych ADR. Towary te, stwarzają  zagrożenie dla zdrowia, bezpieczeństwa, mienia lub środowiska."
                  />
                  <meta
                    name="keywords"
                    content="transport materiałów niebezpiecznych ADR, ADR,transport materiałów niebezpiecznych "
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/transport-materialow-niebezpiecznych" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="Transport Materiałów Niebezpiecznych ADR"
                  titlePosition="right"
                  color="blue"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route> 
        <Route
          path="/transport-drobnicowy"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Transport Drobnicowy - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Oferujemy transport drobnicowy, czyli dostawę towarów o niewielkich gabarytach. W tym przypadku, przestrzeń ładunkowa dzielona jest na kilku dostawców."
                  />
                  <meta name="keywords" content="transport drobnicowy, dostawa towarów o niewielkich rozmiaru" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/transport-drobnicowy" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Szybki Transport Drobnicowy i Całopojazdowy, Transport Multimodalny w Firmie PHU MARPOL"
                  titlePosition="right"
                  color="blue"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
         <Route
          path="/naczepy-firanki"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Naczepy Firanki - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Naczepy firanki, są to naczepy osłonięte kurtyną, posiadają mnóstwo zalet, ponieważ ułatwiają załadunek i rozładunek towarów co usprawnia realizację zleceń."
                  />
                  <meta name="keywords" content="naczepy firanki, naczepy kurtynowe, naczepy kurtyna" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/naczepy-firanki" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Naczepy Firanki"
                  titlePosition="right"
                  color="blue"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
          <Route
          path="/naczepy-plandeka"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Naczepy Plandeka, Koffer - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Naczepy plandeka to jedna z najczęściej używanych naczep w transporcie, ponieważ rozładunek może być dokonany tyłem, bokiem oraz górą skrzyni ładunkowej."
                  />
                  <meta name="keywords" content="naczepy plandeka" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/naczepy-plandeka" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Naczepy z Plandeką lub ze Stałą Zabudową (Koffer)"
                  titlePosition="left"
                  color="yellow"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/naczepy-izotermy"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Naczepy Izotermy - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Nasza firma posiada w swojej ofercie naczepy izotermy. Ich zadaniem jest ograniczanie wymiany ciepła pomiędzy wnętrzem kontenera, a środowiskiem zewnętrznym."
                  />
                  <meta name="keywords" content="naczepy izotermy" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/naczepy-izotermy" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Naczepy Izotermy"
                  titlePosition="left"
                  color="yellow"
                  lang="pl"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* LANG SZWEDZKI PODSTRONY */}
        {/* transport chłodniczy sv */}
        <Route
          path="/sv/kyltransport"
          element={
            <HelmetProvider>
              <Helmet>
                <title>Kyltransport - PHU MARPOL</title>
                <meta
                  name="description"
                  content="Vi erbjuder kyltransporter av produkter som kött, fisk, skaldjur, mejeriprodukter, frukt och grönsaker, växter, jordbruksprodukter, medicinska produkter, kosmetika"
                />
                <meta name="keywords" content="Kyltransporter" />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/sv/kyltransport" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Kyltransport"
                titlePosition="right"
                color="blue"
                lang="sv"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
        {/* Transport międzynarodowy sv */}
             <Route
          path="/sv/internationell-transport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Internationell transport - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vi utför transportbeställningar och garanterar att alla typer av varor förbereds för transport på nationella och internationella vägar"
                  />
                  <meta name="keywords" content = "internationella transporter" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/internationell-transport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="Internationell transport"
                  titlePosition="left"
                  color="yellow"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
        {/* Transport w temperaturze kontrolowanej */}
            <Route
          path="/sv/temperaturkontrollerad-transport-och-dedikerad-expresstransport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Temperaturkontrollerad transport och dedikerad expresstransport
                  </title>
                  <meta
                    name="description"
                    content="Vi erbjuder expressleveranser och transporter av varor som kräver särskilda temperaturförhållanden. Vi garanterar säker och snabb transport"
                  />
                  <meta name="keywords" content="temperaturkontrollerad, express transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/temperaturkontrollerad-transport-och-dedikerad-expresstransport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <EkspresowySite
                  title="Temperaturkontrollerad transport och dedikerad expresstransport"
                  titlePosition="right"
                  color="blue"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Transport multimodalny sv transport drobnicowy sv */}
             <Route
          path="/sv/snabb-styckegods-och-full-lastbilstransport-multimodal-transport-pa"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Snabb styckegods och full lastbilstransport, multimodal transport på PHU MARPOL- PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vi erbjuder samlastningstransporter, dvs. leveranser av gods med små dimensioner. I detta fall delas lastutrymmet mellan flera leverantörer"
                  />
                  <meta name="keywords" content="transport drobnicowy, dostawa towarów o niewielkich rozmiaru" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/snabb-styckegods-och-full-lastbilstransport-multimodal-transport-pa" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Snabb styckegods och full lastbilstransport, multimodal transport på PHU MARPOL"
                  titlePosition="right"
                  color="blue"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Firanki sv */}
           <Route
          path="/sv/curtainside-semitrailers"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Curtainside semitrailers - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Curtain trailers, som är halvtrailers med gardin-sidor, har många fördelar eftersom de underlättar lastning och lossning av varor, vilket förbättrar orderhanteringen."
                  />
                  <meta name="keywords" content="riddarna" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/curtainside-semitrailers" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Curtainside semitrailers"
                  titlePosition="right"
                  color="blue"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* ADR SV */}
            <Route
          path="/sv/transport-av-farligt-material-ADR"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                    Transport av farligt material ADR - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Vi har erfarenhet av transport av ADR-farliga material. Dessa varor utgör en risk för hälsa, säkerhet, egendom eller miljö."
                  />
                  <meta
                    name="keywords"
                    content="ADR-transport av farligt material"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/transport-av-farligt-material-ADR" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="Transport av farligt material ADR"
                  titlePosition="right"
                  color="blue"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route> 
        {/* Plandeka sv koffer sv */}
            <Route
          path="/sv/pahangsvagnar-med-presenning-eller-med-fast-kaross-koffer"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Påhängsvagnar med presenning eller med fast kaross (Koffer) - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Släpvagnar av presenning är en av de vanligaste släpvagnarna inom transport, eftersom de kan lossas från baksidan, sidan och toppen av lastbädden"
                  />
                  <meta name="keywords" content="Påhängsvagnar av presenning" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/pahangsvagnar-med-presenning-eller-med-fast-kaross-koffer" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Påhängsvagnar med presenning eller med fast kaross (Koffer)"
                  titlePosition="left"
                  color="yellow"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* izotermy sv */}
           <Route
          path="/sv/isotherm-semitrailers"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Isotherm semitrailers - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vårt företag erbjuder isotermvagnar. Deras funktion är att begränsa värmeutbytet mellan behållarens inre och den yttre miljön."
                  />
                  <meta name="keywords" content="isotermiska släpvagnar" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/sv/isotherm-semitrailers" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Isotherm semitrailers"
                  titlePosition="left"
                  color="yellow"
                  lang="sv"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
    {/* LANG NIEMIECKI PODSTRONY */}
        <Route
          path="/de/internationale-spedition"  //TRANSPORT MIEDZYNARODOWY
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Internationale Spedition - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Wir führen Transportaufträge aus und garantieren Unterstützung bei der Erledigung aller Formalitäten zur Vorbereitung von Gütern aller Art für den Transport auf nationalen und internationalen Strecken."
                  />
                  <meta name="keywords" content="internationale spedition" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/internationale-spedition" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="Internationale spedition"
                  titlePosition="left"
                  color="yellow"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
        <Route
          path="/de/kuhltransport"
          //Transport chlodniczy chłodniczy
          element={
            <HelmetProvider>
              <Helmet>
                <title>Kuhltransport - PHU MARPOL</title>
                <meta
                  name="description"
                  content="Wir bieten gekühlten Transport von Produkten wie Fleisch, Fisch, Meeresfrüchten, Milchprodukten, Obst und Gemüse, Pflanzen, landwirtschaftlichen und medizinischen Produkten sowie Kosmetika."
                />
                <meta name="keywords" content="kuhltransport" />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/de/kuhltransport" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Kühltransport"
                titlePosition="right"
                color="blue"
                lang="de"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
        <Route
          path="/de/tautliner"
          //Naczepy firnaki
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Tautliner - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Curtain-Sattelanhänger, d. h. Anhänger mit Schiebeplanen, haben viele Vorteile, da sie das Be- und Entladen von Waren erleichtern, was die Auftragsabwicklung wesentlich effizienter macht"
                  />
                  <meta name="keywords" content="tautliner" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/tautliner" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Tautliner"
                  titlePosition="right"
                  color="blue"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/de/isothermen-auflieger"
          //Naczepy izotermy
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Isothermen Auflieger - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Unser Unternehmen bietet Isotherm-Anhänger an. Ihre Aufgabe ist es, den Wärmeaustausch zwischen dem Inneren des Containers und der äußeren Umgebung zu begrenzen"
                  />
                  <meta name="keywords" content="isothermen auflieger" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/isothermen-auflieger" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Isothermen-Auflieger "
                  titlePosition="left"
                  color="yellow"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/de/planenauflieger"
          //Naczepy plandeka
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Planenauflieger - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Planenauflieger gehören zu den am häufigsten verwendeten Anhängern für den Transport, da sie von hinten, von der Seite oder von oben auf der Ladefläche entladen werden können"
                  />
                  <meta name="keywords" content="planenauflieger" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/planenauflieger" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Sattelauflieger mit festem Aufbau (Koffer) oder mit Plane"
                  titlePosition="left"
                  color="yellow"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/de/temperaturgesteuerter-transport-von-materialien"
          // Kontrolowana Temperatura
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Temperaturgesteuerter Transport Von Materialien - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Unser Angebot umfasst einen Express-Transportdienst und den Transport von Waren, die besondere Temperaturbedingungen erfordern. Wir garantieren einen sicheren und schnellen Transport"
                  />
                  <meta name="keywords" content="temperaturgesteuert" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/temperaturgesteuerter-transport-von-materialien" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <EkspresowySite
                  title="Spezieller Express-Transport, temperaturgesteuerter Transport von Materialien "
                  titlePosition="right"
                  color="blue"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/de/multimodaler-transport"
          //Transport Drobnicowy
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Multimodaler Transport- PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Wir bieten Sammeltransporte an, d.h. die Lieferung von Gütern mit kleinen Abmessungen an bestimmte Orte. In diesem Fall wird der Laderaum von mehreren Anbietern gemeinsam genutzt"
                  />
                  <meta name="keywords" content="multimodaler-transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/multimodaler-transport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Schneller Stückgut- und Komplettladungstransport, multimodaler Transport"
                  titlePosition="right"
                  color="blue"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/de/gefahrguttransport"
          //Transport ADR
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  ADR-Transport von Gefahrgut - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Wir haben Erfahrung mit dem Transport von ADR-Gefahrgut. Diese Waren stellen eine Gefahr für die Gesundheit, die Sicherheit, das Eigentum oder die Umwelt dar"
                  />
                  <meta
                    name="keywords"
                    content="gefahrguttransport "
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/de/gefahrguttransport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="Gefahrguttransport"
                  titlePosition="right"
                  color="blue"
                  lang="de"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        /> 
        {/* LANG EN */}
        {/* Transport Chłodniczy en*/}
        <Route
          path="/en/refrigerated-transport"
          element={
            <HelmetProvider>
              <Helmet>
                <title>Refrigerated Transport - PHU MARPOL</title>
                <meta
                  name="description"
                  content="We provide refrigerated transport of products such as meat, fish, seafood, dairy products, fruit and vegetables, plants, agricultural, medical supplies and cosmetics"
                />
                <meta name="keywords" content="refrigerated transport" />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/en/refrigerated-transport" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Refrigerated Transport"
                titlePosition="right"
                color="blue"
                lang="en"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
          {/* Transport międzynarodowy en */}
          <Route
          path="/en/international-transport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>International Transport - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="We carry out transport orders, guaranteeing assistance in dealing with all the formalities of preparing any type of goods for transport on national and international routes"
                  />
                  <meta name="keywords" content="international transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/international-transport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="International Transport"
                  titlePosition="left"
                  color="yellow"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
        {/* Transport w temperaturze kontrolowanej en*/}
        <Route
          path="/en/temperature-controlled-transport-dedicated-express-transport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Temperature Controlled Transport | Express Transport
                  </title>
                  <meta
                    name="description"
                    content="Our offer includes an express transport service and the transport of goods requiring special temperature conditions. We guarantee safe and fast transport"
                  />
                  <meta name="keywords" content="temperature controlled transport, dedicated express transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/temperature-controlled-transport-dedicated-express-transport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <EkspresowySite
                  title="Temperature Controlled Transport and Dedicated Express
                  Transport"
                  titlePosition="right"
                  color="blue"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Transport Drobnicowy en */}
        <Route
          path="/en/groupage-transport-full-truckload-multimodal-transport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Groupage Transport | Full Truckload | Multimodal Transport</title>
                  <meta
                    name="description"
                    content="We offer groupage transport, i.e. the delivery to designated locations of goods with small dimensions. In this case, the loading space is shared between several suppliers"
                  />
                  <meta name="keywords" content="groupage transport, full truckload, multimodal transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/groupage-transport-full-truckload-multimodal-transport"/>
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Fast Groupage and Full Truckload, multimodal transport"
                  titlePosition="right"
                  color="blue"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Transport materiałów niebezpiecznych ADR */}
               <Route
          path="/en/adr-transport-hazardous-materials"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  ADR Transport Hazardous Materials - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="We are experienced in the transport of ADR hazardous materials. These goods, pose a risk to health, safety, property or the environment"
                  />
                  <meta
                    name="keywords"
                    content="ADR Transport Hazardous Materials "
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/adr-transport-hazardous-materials" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="ADR transport of hazardous materials"
                  titlePosition="right"
                  color="blue"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
{/* Naczepy Firanka en*/}
<Route
          // path="/en/curtain-semi-trailers"
          path="/en/tautliner"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Tautliner - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Curtain semi-trailers, which are curtain-sided trailers, have many advantages because they facilitate the loading and unloading of goods, which makes order processing very efficient"
                  />
                  <meta name="keywords" content="tautliner, curtainsider, curtain side truck" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/tautliner" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Tautliner"
                  titlePosition="right"
                  color="blue"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Naczpey Plandeka */}
        <Route
          path="/en/koffer-tarpaulin-truck"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Semi-trailers Tarpaulin - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Tarpaulin semi-trailers are one of the most commonly used trailers in transport, as they can be unloaded from the rear, side and top of the load bed"
                  />
                  <meta name="keywords" content="Semi-trailers Tarpaulin" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/en/koffer-tarpaulin-truck" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Koffer Truck or Tarpaulin Truck"
                  titlePosition="left"
                  color="yellow"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
                <Route
          path="/en/isothermal-trailer"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Isothermal Trailer - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Our company offers isotherm trailers. Their purpose is to limit the exchange of heat between the interior of the container and the external environment"
                  />
                  <meta name="keywords" content="isothermal trailer" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/isothermal-trailer" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Isothermal Trailer"
                  titlePosition="left"
                  color="yellow"
                  lang="en"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
          {/* LANG FR PODSTRONY */}
          <Route
          // transport w temperaturze kontrolowanej
          path="/fr/transport-express-dedie-temperature-controlee"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Transport Express Dedie | Temperature Controlee
                  </title>
                  <meta
                    name="description"
                    content="Nous proposons un service de transport express et le transport de marchandises nécessitant des conditions de température particulières. Nous garantissons un transport sûr et rapide"
                  />
                  <meta name="keywords" content="température contrôlée, transport express, transport de marchandises nécessitant des conditions de température particulières" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/transport-express-dedie-temperature-controlee" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>        
                <EkspresowySite
                  title="Transport à température contrôlée et transport express dédié"
                  titlePosition="right"
                  color="blue"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/fr/transports-internationaux"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Transports Internationaux- PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Nous réalisons des commandes de transport, en garantissant la préparation de tout type de marchandises pour le transport sur les routes nationales et internationales."
                  />
                  <meta name="keywords" content="transport international, affectations de transport, routes nationales" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/transports-internationaux" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="Transports Internationaux"
                  titlePosition="left"
                  color="yellow"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
          <Route
          // TRANSPORT CHŁODNICZY PO FR
          path="/fr/transport-frigorifique"
          element={
            <HelmetProvider>
              <Helmet>
                <title>Transport frigorifique- PHU MARPOL</title>
                <meta
                  name="description"
                  content="Nous assurons le transport réfrigéré de produits tels que la viande, le poisson, les fruits de mer, les produits laitiers, les fruits et légumes, les plantes"
                />
                <meta name="keywords" content="transport frigorifique, transport de viande, transport de poisson, transport de produits laitiers," />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/fr/transport-frigorifique" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Transport Frigorifique"
                titlePosition="right"
                color="blue"
                lang="fr"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
  
        <Route
        // TRANSPORT DROBNICOWY PO FR
          path="/fr/cargo-general-transport-multimodal"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Cargo Général | Transport Multimodal - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Nous proposons le transport de groupage, c'est-à-dire la livraison de marchandises de petites dimensions. Dans ce cas, l'espace de chargement est partagé entre plusieurs fournisseurs."
                  />
                  <meta name="keywords" content="transport de groupage, livraison de marchandises de petite taille" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/cargo-general-transport-multimodal" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Groupage Rapide et Transport Complet par Camion, Transport Multimodal à PHU MARPOL"
                  titlePosition="right"
                  color="blue"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
                  <Route
          path="/fr/semi-remorques-bachees"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Semi-remorques bâchées- PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Les remoarques à rideaux, qui sont des semi-remorques à rideaux latéraux, présentent de nombreux avantages car elles facilitent le chargement et le déchargement des marchandises, ce qui améliore le traitement des commndes."
                  />
                  <meta name="keywords" content="semi-remorques curtainsider, semi-remorques curtainsider" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/semi-remorques-bachees" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Semi-remorques bâchées"
                  titlePosition="right"
                  color="blue"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
            <Route
          path="/fr/semi-remorques-bache-koffer"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Semi-remorques Bâche, Koffer - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Les semi-remorques à bâche sont l'une des remorques les plus utilisées dans le transport, car elles peuvent être déchargées par l'arrière, le côté et le haut du plateau de chargement."
                  />
                  <meta name="keywords" content="semi-remorques bâchées, koffer" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/semi-remorques-bache-koffer" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Semi-remorques bâchées ou à caisse fixe (Koffer)"
                  titlePosition="left"
                  color="yellow"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
               <Route
          path="/fr/adr"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Transport de matières dangereuses ADR - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Nous sommes expérimentés dans le transport de matières dangereuses ADR. Ces marchandises présentent un risque pour la santé, la sécurité, les biens ou l'environnement."
                  />
                  <meta
                    name="keywords"
                    content="transport de matières dangereuses ADR, ADR,transport de matières dangereuses"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/adr" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="Transport de matières dangereuses ADR"
                  titlePosition="right"
                  color="blue"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route> 
        <Route
          path="/fr/semi-remorques-isothermes"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Semi-remorques isothermes - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Notre société propose des remorques isothermes. Leur but est de limiter l'échange de chaleur entre l'intérieur du conteneur et l'environnement extérieur."
                  />
                  <meta name="keywords" content="Semi-remorques isothermes" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/fr/semi-remorques-isothermes" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Semi-Remorques Isothermes "
                  titlePosition="left"
                  color="yellow"
                  lang="fr"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Lang Norwecki nb */}
        {/* transport chłodniczy nb */}
        <Route
          path="/nb/kjoletransport"
          element={
            <HelmetProvider>
              <Helmet>
                <title>Kjøletransport - PHU MARPOL</title>
                <meta
                  name="description"
                  content="Vi tilbyr kjøletransport av produkter som kjøtt, fisk, sjømat, meieriprodukter, frukt og grønnsaker, planter, landbruksvarer, medisinsk utstyr og kosmetikk."
                />
                <meta name="keywords" content="Kjøletransport" />
                <meta name="robots" content="index" />
                <meta name="author" content="Jakub Stępnik" />
                <link rel="canonical" href="https://phumarpol.eu/nb/kjoletransport" />
              </Helmet>
              <Suspense fallback={<Loading/>}>
              <ChlodnieSite
                title="Kjøletransport"
                titlePosition="right"
                color="blue"
                lang="nb"
              />
              </Suspense>
            </HelmetProvider>
          }
        ></Route>
        {/* transport miedzynarodowy  nb*/}
            <Route
          path="/nb/international-transport" 
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>International Transport - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vi utfører transportordrer, og garanterer hjelp til å håndtere alle formaliteter for å forberede alle typer varer for transport på nasjonale og internasjonale ruter"
                  />
                  <meta name="keywords" content="international transport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/international-transport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <InternationalSite
                  title="International Transport"
                  titlePosition="left"
                  color="yellow"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
        {/*  transport temperaturze kontrolowanej nb*/}
        <Route
          path="/nb/transport-kontrollert-temperatur-og-dedikert-ekspresstransport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                  Transport i kontrollert temperatur, og dedikert ekspresstransport | Transportu Ekspresowego
                  </title>
                  <meta
                    name="description"
                    content="Vi tilbyr ekspresstransport og transport av varer som krever spesielle temperaturforhold. Vi garanterer sikker og rask transport"
                  />
                  <meta name="keywords" content="Transport i kontrollert temperatur, og dedikert ekspresstransport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/transport-kontrollert-temperatur-og-dedikert-ekspresstransport" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <EkspresowySite
                  title="Transport i kontrollert temperatur, og dedikert ekspresstransport"
                  titlePosition="right"
                  color="blue"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* Transport drobnicowy */}
          <Route
          path="/nb/rask-stykkgods-og-full-lastebil-multimodal-transport"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Rask stykkgods og full lastebil, multimodal transport hos - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vi tilbyr gruppetransport, dvs. levering av varer med små dimensjoner. I dette tilfellet deles lasterommet mellom flere leverandører"
                  />
                  <meta name="keywords" content="Vi tilbyr gruppetransport" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/rask-stykkgods-og-full-lastebil-multimodal-transport"/>
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <DrobnicowySite
                  title="Rask stykkgods og full lastebil, multimodal transport hos PHU MARPOL"
                  titlePosition="right"
                  color="blue"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* ADR nb */}
        <Route
          path="/nb/transport-av-farlige-stoffer-ADR"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>
                    Transport av farlige stoffer ADR - PHU MARPOL
                  </title>
                  <meta
                    name="description"
                    content="Vi har erfaring med transport av ADR-farlig gods. Disse varene utgjør en risiko for helse, sikkerhet, eiendom eller miljø."
                  />
                  <meta
                    name="keywords"
                    content="transport av farlige materialer ADR, ADR,transport av farlige materialer"
                  />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/transport-av-farlige-stoffer-ADR" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <AdrSite
                  title="Transport av farlige stoffer ADR"
                  titlePosition="right"
                  color="blue"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        ></Route>
        {/* naczey firanka nb */}
           <Route
          path="/nb/curtainsider-semitrailere"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Curtainsider semitrailere - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Gardinsemitrailere, som er trailere med gardinsider, har en rekke fordeler fordi de letter lasting og lossing av varer, noe som gjør det lettere å fullføre ordrer"
                  />
                  <meta name="keywords" content="curtainsider" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/curtainsider-semitrailere" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <FirankaSite
                  title="Curtainsider semitrailere"
                  titlePosition="right"
                  color="blue"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        /> 
        {/* naczpey plandeka nb */}
           <Route
          path="/nb/semitrailere-med-presenning-eller-fast-karosseri-koffer"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Semitrailere med presenning eller fast karosseri (Koffer) - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Presenningstilhengere er en av de mest brukte tilhengerne i transport, ettersom de kan losses bakfra, fra siden eller fra toppen av lasteplanet"
                  />
                  <meta name="keywords" content="koffer, semitrailere med presenning" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/semitrailere-med-presenning-eller-fast-karosseri-koffer" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <PlandekaSite
                  title="Semitrailere med presenning eller fast karosseri (Koffer)"
                  titlePosition="left"
                  color="yellow"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
        {/* naczepy izotermy nb */}
         <Route
          path="/nb/isotherm-semitrailere"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <title>Isotherm Semitrailere - PHU MARPOL</title>
                  <meta
                    name="description"
                    content="Vårt firma tilbyr isoterm tilhengere. Deres oppgave er å begrense utvekslingen av varme mellom det indre av beholderen og det ytre miljøet"
                  />
                  <meta name="keywords" content="isotherm semitrailere" />
                  <meta name="robots" content="index" />
                  <meta name="author" content="Jakub Stępnik" />
                  <link rel="canonical" href="https://phumarpol.eu/nb/isotherm-semitrailere" />
                </Helmet>
                <ScrollUp />
                <Suspense fallback={<Loading/>}>
                <IzotermySite
                  title="Isotherm Semitrailere"
                  titlePosition="left"
                  color="yellow"
                  lang="nb"
                />
                </Suspense>
              </HelmetProvider>
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
